.dialogOverlay[data-state="open"] {
  -webkit-font-smoothing: antialiased;
  opacity: 1;
  animation: overlayShow 300ms;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.4, 1.1);
}

.dialogOverlay[data-state="closed"] {
  -webkit-font-smoothing: antialiased;
  opacity: 0;
  animation: fadeOut 300ms;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.4, 1.1);
}

.dialogContent[data-state="open"] {
  -webkit-font-smoothing: antialiased;
  animation: contentShow 300ms;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.4, 1.1);
}

.dialogContent[data-state="closed"] {
  -webkit-font-smoothing: antialiased;
  opacity: 0;
  animation: contentShow 300ms;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.4, 1.1);
}

@keyframes overlayShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: translate(-50%, -45%) scale(0.96);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
